import * as React from 'react';
import { graphql } from 'gatsby';
import tw, { styled } from 'twin.macro';
import Layout from '../components/Layout';

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { eq: "common" }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Container = styled.div`
  ${tw`min-h-screen text-gray-600`}

  h2 {
    ${tw`font-bold pb-1`}
  }

  h3 {
    ${tw`font-semibold pb-1`}
  }

  p {
    ${tw`pb-4`}
  }
`;

function LegalNotice() {
  return (
    <Layout>
      <Container>
        <h2>Impressum</h2>

        <h3>Verantwortlicher</h3>
        <p>Klaus Vogelgesang</p>

        <h2 id="contact">Kontakt</h2>
        <p>
          <a href="mailto:&#107;&#111;&#110;&#116;&#097;&#107;&#116;&#064;&#107;&#108;&#097;&#117;&#115;&#118;&#111;&#103;&#101;&#108;&#103;&#101;&#115;&#097;&#110;&#103;&#046;&#100;&#101;">
            &#107;&#111;&#110;&#116;&#097;&#107;&#116;&#064;&#107;&#108;&#097;&#117;&#115;&#118;&#111;&#103;&#101;&#108;&#103;&#101;&#115;&#097;&#110;&#103;&#046;&#100;&#101;
          </a>
        </p>

        <h3>Technische Umsetzung</h3>
        <p>Till Hainbach & Konstantin Dauer</p>

        <h2>Rechtliche Hinweise</h2>
        <p>
          Alle Angaben unseres Internetangebotes wurden sorgfältig geprüft. Wir
          bemühen uns, dieses Informationsangebot aktuell und inhaltlich richtig
          sowie vollständig anzubieten. Dennoch ist das Auftreten von Fehlern
          nicht völlig auszuschließen. Eine Garantie für die Vollständigkeit,
          Richtigkeit und letzte Aktualität kann daher nicht übernommen werden.
        </p>
        <p>
          Der Herausgeber kann diese Website nach eigenem Ermessen jederzeit
          ohne Ankündigung verändern und / oder deren Betrieb einstellen. Er ist
          nicht verpflichtet, Inhalte dieser Website zu aktualisieren.
        </p>
        <p>
          Der Zugang und die Benutzung dieser Website geschieht auf eigene
          Gefahr des Benutzers. Das Bundesministerium der Justiz und für
          Verbraucherschutz ist nicht verantwortlich und übernimmt keinerlei
          Haftung für Schäden, u.a. für direkte, indirekte, zufällige, vorab
          konkret zu bestimmende oder Folgeschäden, die angeblich durch den oder
          in Verbindung mit dem Zugang und/oder der Benutzung dieser Website
          aufgetreten sind.
        </p>
        <p>
          Der Betreiber übernimmt keine Verantwortung für die Inhalte und die
          Verfügbarkeit von Websites Dritter, die über externe Links dieses
          Informationsangebotes erreicht werden. Der Herausgeber distanziert
          sich ausdrücklich von allen Inhalten, die möglicherweise straf- oder
          haftungsrechtlich relevant sind oder gegen die guten Sitten verstoßen.
        </p>
        <p>
          Bei der Anforderung von Inhalten aus diesem Internetangebot werden
          Zugriffsdaten gespeichert. Das sind Daten über die Seite, von der aus
          die Datei angefordert wurde, der Name der angeforderten Datei, das
          Datum, Uhrzeit und Dauer der Anforderung, die übertragene Datenmenge
          sowie der Zugriffsstatus (z.B. Datei übertragen, Datei nicht
          gefunden). Diese gespeicherten Daten werden ausschließlich zu
          statistischen Zwecken ausgewertet und auf keinen Fall an Dritte
          weitergeleitet.
        </p>
        <p>
          Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe
          persönlicher oder geschäftlicher Daten (E-Mail Adressen, Namen,
          Anschriften) besteht, so erfolgt die Preisgabe dieser Daten seitens
          des Nutzers auf ausdrücklich freiwilliger Basis.
        </p>
      </Container>
    </Layout>
  );
}

export default LegalNotice;
